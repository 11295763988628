import { hextorstr, KEYUTIL, KJUR, stob64 } from "jsrsasign";
const qz = require("qz-tray");

const crt = `-----BEGIN CERTIFICATE-----
MIID/TCCAuWgAwIBAgIUATcszZ8hxodJ8GATbo9ZQ29iwLEwDQYJKoZIhvcNAQEL
BQAwgYwxCzAJBgNVBAYTAklEMRMwEQYDVQQIDApKYXdhIFRpbXVyMQ4wDAYDVQQH
DAVOZ2F3aTERMA8GA1UECgwIU0lBIFNPRlQxCzAJBgNVBAsMAklUMRIwEAYDVQQD
DAlsb2NhbGhvc3QxJDAiBgkqhkiG9w0BCQEWFXlhaHlhYWJkdWxoQGdtYWlsLmNv
bTAgFw0yMTExMjEwMjU4MjFaGA8yMDUzMDUxNjAyNTgyMVowgYwxCzAJBgNVBAYT
AklEMRMwEQYDVQQIDApKYXdhIFRpbXVyMQ4wDAYDVQQHDAVOZ2F3aTERMA8GA1UE
CgwIU0lBIFNPRlQxCzAJBgNVBAsMAklUMRIwEAYDVQQDDAlsb2NhbGhvc3QxJDAi
BgkqhkiG9w0BCQEWFXlhaHlhYWJkdWxoQGdtYWlsLmNvbTCCASIwDQYJKoZIhvcN
AQEBBQADggEPADCCAQoCggEBAJZwBEqp+z933k5qbZk6iapqu6wO/dpxo/lCMP6m
Xo+pQ1ypKQv0iaPeoDBg7EFIaZgI44gzvJI/uMnqrOZshKayY6m7wfdgHwpwBmPz
8g3BaFqtG8QM2eiK8S2vWSlG4ZMqNf9gqnkauW5iIbrqvRmnPjv5QX3wrFuwHOuU
IzI0pdbBcGCnZECn/JMUQNqvuoZHCL3Qn72tjOIL8Dywx/oLrD9Sf7jlDF8kv5JE
ef8ag39aWE9QfD6srg4NMdEjsldrGwUDulTPwi1r0Gda53sDZf+m3c/fz/h8aPCB
EurnfBH6CmxMddkM1eBDlLRm4A6Q6qs/kP3/BkHsVBw+kV0CAwEAAaNTMFEwHQYD
VR0OBBYEFOwyPuLbdZX2pMlhSqrbNVZ0WBmJMB8GA1UdIwQYMBaAFOwyPuLbdZX2
pMlhSqrbNVZ0WBmJMA8GA1UdEwEB/wQFMAMBAf8wDQYJKoZIhvcNAQELBQADggEB
AAJT4YfJ9I6pOpbbSgbOat96zYoxnSO8N3Dlhh7IS2X4SuKuAkWeapd+O9C+eOPA
m4cv+amCy1OjqP6+jYvDzZ5PSJV8QZfueqTxeYdvHlxM/iy7xxlFrya3jzefxJEQ
9cc+g3+ioZpV6Sx47yeSDniaF5JpMoR1A3sgXy/7/ausa3xdW5ui72eIsGlJuZQx
8BHKhN4qfypSXqGW9mPVQTeOcwHeikXSsfjL4BzDLInwIzJvFhckGDDIGb+jRuZl
lU5HFhIPjIKrtiQ0ChId2a4dm9wMNyaxXYFdTKAJ9Dab7ANWM9YuHy2NiYY7bMsa
ObkqAlkCRovxJwn5NkBHbBg=
-----END CERTIFICATE-----`;
const privateKey = `-----BEGIN PRIVATE KEY-----
MIIEvQIBADANBgkqhkiG9w0BAQEFAASCBKcwggSjAgEAAoIBAQCWcARKqfs/d95O
am2ZOomqarusDv3acaP5QjD+pl6PqUNcqSkL9Imj3qAwYOxBSGmYCOOIM7ySP7jJ
6qzmbISmsmOpu8H3YB8KcAZj8/INwWharRvEDNnoivEtr1kpRuGTKjX/YKp5Grlu
YiG66r0Zpz47+UF98KxbsBzrlCMyNKXWwXBgp2RAp/yTFEDar7qGRwi90J+9rYzi
C/A8sMf6C6w/Un+45QxfJL+SRHn/GoN/WlhPUHw+rK4ODTHRI7JXaxsFA7pUz8It
a9BnWud7A2X/pt3P38/4fGjwgRLq53wR+gpsTHXZDNXgQ5S0ZuAOkOqrP5D9/wZB
7FQcPpFdAgMBAAECggEAM6u57aNAxuX3F1v61PyEOGJ8n3Zuvs71kTGo/m5KYHG0
z2jSzmZpuD1JLBS+URX8Hto+vJN4m4sDf2b5iBOR5Ge1T6FL2BnNz5n2t/IcG5lo
rGx08vuvBO5kNFrb9w9/fzqqXVLoerwxILbX+wmRdfQ5rgPI+ObnbH6rEEdLZ3yj
AeWd2Gs06onTReCZqhQD9iQnbnKjJ+VGaYdCeJp+93E9Oq/0Mi/VkH/0rHqjWNzi
NJp53zAoFcDn3ytU8I+hnMt79K/jKVBTgcZc4RZiW8Zba4dGEPAL4rslj8EWqtX/
s4lRB16uwXvMMunGHoCUrEV6jM95Elm1xtC4SOgXCQKBgQDEzyBrj5HRGXB3a0ZW
06vLQgw/NDignMwUgoDjAsfXLoPnIcXRHvT+KdupoXfluntiFLw0Tt88M1AEPyDU
P7WrqTh3Xjq3JiaWYP2tiA+gI3iFmVIAqBfqcBbRfY8+wKwUgKluZaaaV7sJmVBM
3b8R28dTV63Xwn51oPxC08Sh1wKBgQDDrp5zli8xmdKfnKBcOX28bb0fn0T1HUg1
ODKA7xEADtTwCXOsvA44Aq6tH+msLd+f3T89wN5YeVkBbEM6p7DrIYE3W8XC9U15
kGgORWggTxiFiHEYeR4GNYvSyBigsWC/GO81AQh1IEInF3VIJ18BtQay5ACnidnz
/18HsFPn6wKBgQCan2SIPQ7B6iurFvP3SWviUCzRB1UqEqZfwrxCHjAlhh0JXUZO
avTuLrKuzfYl3eIMB+BpdEH8yQ+nlnVTbA5uJJABcF3PAME2SlNjn88u8E9DH1cG
vwmzHYc/5VbCZZuMrBzxaBmBj0WaYAJvsZPpEvPCLdOd/OdfwTAPzNLilwKBgC+B
JspcvVxHQZCqD2wUMOgbo/wXSEBROYRh/jC62N+wuhUD1mcqyDDkxc8Vnj7EbDN9
HKs7NT85RlhIFhIZP6P1YE82Ik5wCrzmR7yfMOYXDZbtRrdwP8+2FBm5pfDs3tXF
kdzGQW1dxXGECl7X4AKkFdo768TAfH71kfZl4hi9AoGABzhm4eiuLXij1r/DHC/O
zJ9Iw6vxGBmqKNbsb/WlMT0rmhFbFvxm3YswkzWaJS6khhgZfyye51hjzx7FhyfJ
jzqPmoCmIbnwgy8ChAIIBiZRluGegdnLgRL7htnzmuxXda9mRsWce4E+idHXeEIW
M3uioKXGHnPjbVP9P4vVcEk=
-----END PRIVATE KEY-----`;

export default {
  data() {
    return {
      printerDevices: [],
      printerConfig: null,
      printerSelected: '',
      printerStatus:''
    }
  },
  methods: {
    qzAuth() {
      qz.security.setCertificatePromise((resolve) => {
        resolve(crt);
      });
      qz.security.setSignatureAlgorithm("SHA512");
    },
    qzSign() {
      qz.security.setSignatureAlgorithm("SHA512");
      return qz.security.setSignaturePromise((toSign) => {
        return (resolve, reject) => {
          try {
            const pk = KEYUTIL.getKey(privateKey);
            const sig = new KJUR.crypto.Signature({ alg: "SHA512withRSA" });
            sig.init(pk);
            sig.updateString(toSign);
            const hex = sig.sign();
            resolve(stob64(hextorstr(hex)));
          } catch (error) {
            reject(error);
          }
        };
      });
    },
    qzStartConnection() {
      const host = `localhost`;
      const usingSecure = true;
      const config = { host, usingSecure };
      return new Promise((resolve, reject) => {
        if (!qz.websocket.isActive()) {
          this.printerStatus = "Connecting..."
          qz.websocket
            .connect(config)
            .then(() => {
              this.printerStatus = "Connected"
              // eslint-disable-next-line
              // console.info("QZ Tray connected.");
              resolve(true);
            })
            .catch((err) => {
              this.printerStatus = "Connection Error!"
              reject(err)
            });
        } else {
          // eslint-disable-next-line
          console.info("QZ Tray already active.");
          this.printerStatus = "Connected"
          resolve(true)
        }
      });
    },
    qzEndConnection() {
      if (qz.websocket.isActive()) {
        qz.websocket.disconnect().then(() => {
          this.printerStatus = "Disconnected"
          // eslint-disable-next-line
          // console.info("QZ Tray disconnected.");
        });
      }
    },
    qzAvailablePrinters() {
      const self = this;
      if (qz.websocket.isActive()) {
        qz.printers.find().then((data) => {
          self.printerDevices = data;
        });
      }
    },
    qzSetPrinter(deviceName) {
      this.printerConfig = qz.configs.create(deviceName);
      // eslint-disable-next-line
      console.info(`Printer set : ${deviceName}`);
    },
    qzSetDefault() {
      const self = this
      qz.printers.getDefault().then(e => {
        if (e.length > 0) {
          self.printerSelected = e
          self.printerConfig = qz.configs.create(e).then(name => {
            // eslint-disable-next-line
            console.info(`Printer Has Been Set : ${name}`)
          })
        }
      })
    },
    qzPrint(data = []) {
      const self = this;
      return new Promise((resolve, reject) => {
        if (self.printerConfig) {
          qz.print(self.printerConfig, data)
            .then(() => {
              // eslint-disable-next-line
              console.info(`Berhasil Cetak!`);
              resolve(true);
            })
            .catch((e) => {
              reject(e);
            });
        } else {
          self.notify("Printer Tidak Ditemukan","Oops!","danger")
          reject(`Printer belum di set.`);
        }
      });
    },
  },
  async mounted() {
    const self = this
    await self.qzAuth()
    await self.qzSign()
    setTimeout(() => {
      self.qzStartConnection().then(() => {
        // self.qzSetDefault()
        self.qzAvailablePrinters()
        // self.qzSetPrinter("ZDesigner ZD220-203dpi ZPL")
      })
    },100)
  },
  destroyed() {
    this.qzEndConnection();
  },
};
