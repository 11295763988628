<template>
  <div>
    <Portal to="portal-form-new">
      <CForm ref="formNewForm" @submit="appendNew">
        <BRow>
          <BCol sm="12" xl="6">
            <FormInput @input="formNew.barcode = generatedCodeNew" :rules="{required: true}" label="Kode" v-model="formNew.code"></FormInput>
            <FormInput label="Nama" v-model="formNew.name"></FormInput>
            <FormSelectAll :rules="{required: true}" label="Kategori" v-model="formNew.category" endpoint="v1/item_categories/selectkat" nested></FormSelectAll>
            <FormSelectAll :rules="{required: true}" v-model="formNew.brand" label="Merek" endpoint="v1/brands/select2"></FormSelectAll>
          </BCol>
          <BCol sm="12" xl="6">
            <FormSelectAll @option:selected="formNew.barcode = generatedCodeNew" :rules="{required: true}" v-model="formNew.color" label="Warna" endpoint="v1/colors/select2"></FormSelectAll>
            <FormSelectAll @option:selected="formNew.barcode = generatedCodeNew" :rules="{required: true}" v-model="formNew.size" label="Ukuran" endpoint="v1/sizes/select2"></FormSelectAll>
            <BRow cols="1" cols-xl="3">
              <BCol>
                <FormNumber :rules="{required: true}" v-model.number="formNew.sale_price" label="Harga Jual"></FormNumber>
              </BCol>
              <BCol>
                <FormNumber :rules="{required: true}" v-model.number="formNew.purchase_price" label="Harga Beli"></FormNumber>
              </BCol>
              <BCol>
                <FormNumber :rules="{required: true}" v-model.number="formNew.qty" label="Qty"></FormNumber>
              </BCol>
            </BRow>
            <BRow cols="1" cols-xl="3">
              <BCol>
                <CButton block class="mt-2" type="submit">Tambah ke Tabel</CButton>
              </BCol>
              <BCol>
                <FormInput :rules="{required: true}" label="Kode Variasi" v-model="formNew.barcode"></FormInput>
              </BCol>
              <BCol>
                <FormInput label="Barcode Pabrik" v-model="formNew.factory_barcode"></FormInput>
              </BCol>
            </BRow>
          </BCol>
        </BRow>
      </CForm>
    </Portal>
    <CCard title="Tambah Penerimaan">
      <template #action>
        <CButton :to="{name:'receptions'}" variant="outline-primary">Kembali</CButton>
        <CButton @click="$refs.form.onSubmit()" :loading="buttonloading">Simpan</CButton>
        <CButton @click="$refs.form.onSubmit('is_print')" :loading="buttonloading">Simpan dan Cetak</CButton>
      </template>
      <CForm ref="form" @submit="confirmSubmit">
        <BRow cols-sm="2" cols-xl="4">
          <BCol>
            <FormInput readonly label="No Nota" placeholder="Generate Otomatis" v-model="code"></FormInput>
          </BCol>
          <BCol>
            <FormDate label="Tanggal" v-model="formData.date" :rules="{required: true}"></FormDate>
          </BCol>
          <BCol>
            <FormSelectAll label="Pemasok" v-model="supplier" :rules="{required: true}" endpoint="v1/contacts/select2"></FormSelectAll>
          </BCol>
          <BCol>
            <CButton @click="$refs.itemModal.show()" class="mt-2" block><feather-icon icon="SearchIcon"></feather-icon> Cari Produk</CButton>
          </BCol>
        </BRow>
      </CForm>
      <BCard no-body border-variant="primary">
        <BTabs card pills class="mb-2">
          <BTab lazy title="Produk Lama">
            <BTable-simple table-class="text-center" class="mt-2 mb-2" fixed small outlined>
              <BThead>
                <BTr>
                  <BTh rowspan="2">Merek</BTh>
                  <BTh rowspan="2">Artikel</BTh>
                  <BTh rowspan="2">Warna</BTh>
                  <BTh rowspan="2">Harga</BTh>
                  <BTh :colspan="sizeRows.length||1">Ukuran</BTh>
                  <BTh rowspan="2">Jml</BTh>
                  <BTh rowspan="2">Total</BTh>
                  <BTh rowspan="2"></BTh>
                </BTr>
                <BTr>
                  <BTh v-for="(v,k) in sizeRows" :key="k">{{v.size_name}}</BTh>
                </BTr>
              </BThead>
              <BTbody>
                <BTr v-for="(v,i) in details" :key="i">
                  <BTd>{{v.brand_name}}</BTd>
                  <BTd>{{v.code}}</BTd>
                  <BTd>{{v.color_name}}</BTd>
                  <BTd>
                    <InlineInputNumber @onValueChanged="qtySizeChanged(i)" v-model.number="v.price"></InlineInputNumber>
                  </BTd>
                  <BTd v-for="(x,k) in sizeRows" :key="k">
                    <template v-for="(s,l) in v.sizes">
                      <InlineInputNumber @onValueChanged="qtySizeChanged(i)" v-if="x.id_size===s.id_size" :key="x.id_size+'~'+l" v-model.number="s.qty"></InlineInputNumber>
                    </template>
                  </BTd>
                  <BTd>{{v.qty_total}}</BTd>
                  <BTd>Rp.{{v.price_total | formatNumber}}</BTd>
                  <BTd>
                    <CButton size="sm" variant="outline-danger" @click="deleteDetail(i)">X</CButton>
                  </BTd>
                </BTr>
              </BTbody>
            </BTable-simple>
          </BTab>
          <BTab lazy title="Produk Baru">
            <PortalTarget name="portal-form-new"></PortalTarget>
            <BTable-simple table-class="text-center" class="mt-2 mb-2" fixed small outlined>
              <BThead>
                <BTr>
                  <BTh rowspan="2">Merek</BTh>
                  <BTh rowspan="2">Artikel</BTh>
                  <BTh rowspan="2">Warna</BTh>
                  <BTh rowspan="2">Harga</BTh>
                  <BTh :colspan="newSizeRows.length||1">Ukuran</BTh>
                  <BTh rowspan="2">Jml</BTh>
                  <BTh rowspan="2">Total</BTh>
                  <BTh rowspan="2"></BTh>
                </BTr>
                <BTr>
                  <BTh v-for="(v,k) in newSizeRows" :key="k">{{v.size_name}}</BTh>
                </BTr>
              </BThead>
              <BTbody>
                <BTr v-for="(v,i) in details_new" :key="i">
                  <BTd>{{v.brand_name}}</BTd>
                  <BTd>{{v.code}}</BTd>
                  <BTd>{{v.color_name}}</BTd>
                  <BTd>
                    <InlineInputNumber @onValueChanged="qtySizeChangedNew(i)" v-model.number="v.price"></InlineInputNumber>
                  </BTd>
                  <BTd v-for="(x,k) in newSizeRows" :key="k">
                    <template v-for="(s,l) in v.sizes">
                      <InlineInputNumber @onValueChanged="qtySizeChangedNew(i)" v-if="x.id_size===s.id_size" :key="x.id_size+'~'+l" v-model.number="s.qty"></InlineInputNumber>
                    </template>
                  </BTd>
                  <BTd>{{v.qty_total}}</BTd>
                  <BTd>Rp.{{v.price_total | formatNumber}}</BTd>
                  <BTd>
                    <CButton size="sm" variant="outline-danger" @click="deleteDetailNew(i)">X</CButton>
                  </BTd>
                </BTr>
              </BTbody>
            </BTable-simple>
          </BTab>
        </BTabs>
      </BCard>
      <BRow>
        <BCol sm="12" xl="5">
          <FormTextarea v-model="formData.note" label="Catatan"></FormTextarea>
        </BCol>
        <BCol sm="12" xl="3" offset-xl="4">
          <FormNumber :rules="{minimal:1}" disabled class="w-50" :value="subTotal" label="Sub Total"></FormNumber>
          <div class="d-flex justify-content-between">
          <FormNumber :rules="{required: true}" @onChange="discPercentChange" class="w-50" v-model.number="formData.disc_percent" label="Disc %"></FormNumber>
          <FormNumber :rules="{required: true}" @onChange="discTotalChange" class="w-100 ml-1" v-model.number="formData.disc_total" label="Disc Rp."></FormNumber>
          </div>
          <FormNumber :rules="{minimal:1}" disabled class="w-50" :value="grandTotal" label="Grand Total"></FormNumber>
        </BCol>
      </BRow>
      <button type="submit" hidden></button>
    </CCard>
    <BModal lazy ref="itemModal" hide-footer size="xl">
      <ItemIndex only-active-filter non-sale is-modal @importingfn="importing"></ItemIndex>
    </BModal>
  </div>
</template>

<script>
import CCard from '@/utils/components/CCard.vue'
import CButton from '@/utils/components/CButton.vue'
import CForm from '@/utils/components/CForm.vue'
import {BTableSimple,BThead,BTr,BTh,BModal,BTbody,BTd,BTabs,BTab,BCard} from 'bootstrap-vue'
import FormInput from '@/utils/components/FormInput.vue'
import FormDate from '@/utils/components/FormDate.vue'
import FormSelectAll from '@/utils/components/FormSelectAll.vue'
import FormTextarea from '@/utils/components/FormTextarea.vue'
import FormNumber from '@/utils/components/FormNumber.vue'
import ItemIndex from '../items/Index2.vue'

import findIndex from 'lodash/findIndex'
import flatMap from 'lodash/flatMap'
import sumBy from 'lodash/sumBy'
import sortBy from 'lodash/sortBy'
import InlineInputNumber from '@/utils/components/InlineInputNumber.vue'
import ScannerDetector from 'js-scanner-detection'
import qz from '@/utils/mixins/qz-tray'

export default {
  mixins:[qz],
  components: { BCard,CCard, CButton, CForm, FormInput, FormDate, FormSelectAll, BTableSimple,BThead,BTr,BTh, FormTextarea, FormNumber, BModal, ItemIndex, BTbody, BTd, InlineInputNumber,BTabs,BTab },
  data(){
    const formData = {
      date: this.$moment(),
      note: '',
      id_contact: null,
      sub_total: 0,
      disc_percent: 0,
      disc_total: 0,
      grand_total: 0
    }
    const onComplete = (barcode) => {
      this.barcodeScanned(barcode)
    }
    const options = {
      onComplete,
      stopPropagation: true,
      preventDefault: false,
    }
    return {
      formData,
      code:'',
      supplier:null,
      buttonloading: false,
      details:[],
      details_new:[],
      scannerDetect : new ScannerDetector(options),
      formNew:{
        code:'',
        name:'',
        category:null,
        brand:null,
        color:null,
        size:null,
        sale_price:0,
        purchase_price:0,
        qty:1,
        barcode:'',
        factory_barcode:''
      }
    }
  },
  computed:{
    compiledFormNew(){
      const fd = JSON.parse(JSON.stringify(this.formNew))
      let res = {}
      res.code = fd.code
      res.name = fd.name
      res.purchase_price = fd.purchase_price
      res.sale_price = fd.sale_price
      res.qty = fd.qty
      res.barcode = fd.barcode
      res.factory_barcode = fd.factory_barcode
      if(fd.category) {
        res.id_category = fd.category.value
        res.category_name = fd.category.label
      }
      if(fd.brand) {
        res.id_brand = fd.brand.value
        res.brand_name = fd.brand.label
      }
      if(fd.color) {
        res.id_color = fd.color.value
        res.color_name = fd.color.label
      }
      if(fd.size) {
        res.id_size = fd.size.value
        res.size_name = fd.size.label
      }
      return res
    },
    sizeRows(){
      const dt = flatMap(this.details,n => n.sizes)
      let result = []
      for (let i = 0; i < dt.length; i++) {
        const el = dt[i];
        if(findIndex(result,{id_size: el.id_size})<0)
          result.push({
            id_size: el.id_size,
            size_name: el.size_name,
          })
      }
      return sortBy(result,['size_name'])
    },
    newSizeRows(){
      const dt = flatMap(this.details_new,n => n.sizes)
      let result = []
      for (let i = 0; i < dt.length; i++) {
        const el = dt[i];
        if(findIndex(result,{id_size: el.id_size})<0)
          result.push({
            id_size: el.id_size,
            size_name: el.size_name,
          })
      }
      return sortBy(result,['size_name'])
    },
    subTotal(){
      const total = sumBy(this.details,'price_total')
      const totalNew = sumBy(this.details_new,'price_total')
      return total+totalNew
    },
    qtyTotal(){
      const total = sumBy(this.details,'qty_total')
      const totalNew = sumBy(this.details_new,'qty_total')
      return total+totalNew
    },
    grandTotal(){
      const {disc_total} = this.formData
      const subTotal = this.subTotal
      return subTotal-parseFloat(disc_total)
    },
    generatedCodeNew(){
      const {code,color,size} = this.formNew
      let stringify = ""
      if(code) stringify += code.toUpperCase()
      if(color) stringify += `${color.code}`
      if(size) stringify += `${size.code}`
      return stringify
    }
  },
  methods:{
    confirmSubmit(is_print=null){
      this.confirmation(`submitForm`,{parameter:{is_print: is_print ? true: false}})
    },
    async submitForm({is_print}){
      const vm = this
      vm.buttonloading = true
      const formData = JSON.parse(JSON.stringify(vm.formData))
      formData.details = vm.details
      formData.qty_total = vm.qtyTotal
      formData.sub_total = vm.subTotal
      formData.grand_total = vm.grandTotal
      formData.id_contact = vm.supplier.value
      formData.new_products = vm.details_new
      this.$http.post(`v1/receptions/create`,formData,{params:{is_print:is_print??''}})
      .then(async ({data}) => {
        if(is_print && localStorage.getItem('labelPrinter')||null) await vm.qzPrint(data)
      })
      .then(()=>{
        vm.notify(`Data Berhasil Disimpan!`)
        vm.$router.push({name:'receptions'})
        vm.buttonloading = false
      })
      .catch((error) => {
        // eslint-disable-next-line
        console.log(error)
        vm.buttonloading = false
        vm.handleError(error)
      })
    },
    async barcodeScanned(barcode){
      try {
        const {data} = await this.$http.get(`v1/items/findbarcode`,{params:{code:barcode}})
        let imported = JSON.parse(JSON.stringify(this.details))
        const index = findIndex(imported,{id_item: data.id_item, id_color: data.id_color})
        if(index<0){
          imported.push({
            code: data.code_barcode,
            id_item: data.id_item,
            id_brand: data.id_brand,
            brand_name: data.brand_name,
            id_color: data.id_color,
            color_name: data.color_name,
            price: data.purchase_price,
            qty_total: 1,
            price_total: data.purchase_price,
            sizes:[
              {
                id_variant: data.id_variant,
                code: data.code_barcode,
                id_size: data.id_size,
                size_name: data.size_name,
                qty: 1
              }
            ]
          })
        } else {
          const indexSize = findIndex(imported[index].sizes,{id_variant:data.id_variant})
          if(indexSize<0) {
            imported[index].sizes.push({
              id_variant: data.id_variant,
              id_size: data.id_size,
              code: data.code_barcode,
              size_name: data.size_name,
              qty: 1
            })
          } else {
            imported[index].qty_total += 1
            imported[index].price_total += imported[index].price * imported[index].qty_total
            imported[index].sizes[indexSize].qty += 1
          }
        }
        this.$set(this,'details',imported)
        this.$nextTick(() => {
          this.discPercentChange()
        })
      } catch (error) {
        this.handleError(error)
      }
    },
    importing(data){
      let imported = JSON.parse(JSON.stringify(this.details))
      for (let i = 0; i < data.length; i++) {
        const item = data[i];
        let itemcolor = []
        for (let x = 0; x < item.item_variants.length; x++) {
          const variant = item.item_variants[x];
          if(variant.checked) {
            // console.log(variant)
            if(!itemcolor.includes(variant.id_color) && findIndex(imported,{id_item: item.id, id_color: variant.id_color})<0) {
              itemcolor.push(variant.id_color)
              imported.push({
                code: item.code,
                id_item: item.id,
                id_brand: item.id_brand,
                brand_name: item.brand_name,
                id_color: variant.id_color,
                color_name: variant.color_name,
                price: variant.purchase_price,
                qty_total: 1,
                price_total: variant.purchase_price,
                sizes:[
                  {
                    id_variant: variant.id,
                    id_size: variant.id_size,
                    size_name: variant.size_name,
                    code: variant.code,
                    qty: 1
                  }
                ]
              })
            } else {
              const index = findIndex(imported,{id_item: item.id, id_color: variant.id_color})
              if(findIndex(imported[index].sizes,{id_size: variant.id_size})<0) {
                imported[index].sizes.push({
                  id_variant: variant.id,
                  id_size: variant.id_size,
                  size_name: variant.size_name,
                  code: variant.code,
                  qty: 1
                })
              }
              const vm = this
              vm.$nextTick(()=>{
                vm.qtySizeChanged(index)
              })
            }
          }
        }
      }
      this.details = imported
      this.$refs.itemModal.hide()
      this.$nextTick(() => {
        this.discPercentChange()
      })
    },
    qtySizeChanged(key){
      const fd = JSON.parse(JSON.stringify(this.details[key]))
      this.details[key].qty_total = sumBy(fd.sizes,'qty')
      this.details[key].price_total = sumBy(fd.sizes,'qty') * fd.price
      this.discPercentChange()
    },
    qtySizeChangedNew(key){
      const fd = JSON.parse(JSON.stringify(this.details_new[key]))
      this.details_new[key].qty_total = sumBy(fd.sizes,'qty')
      this.details_new[key].price_total = sumBy(fd.sizes,'qty') * fd.price
      this.discPercentChange()
    },
    discPercentChange(){
      this.formData.disc_total = this.subTotal * this.formData.disc_percent / 100
    },
    discTotalChange(){
      this.formData.disc_percent = this.formData.disc_total / this.subTotal * 100
    },
    appendNew(){
      const vm = this
      this.$refs.formNewForm.validationCheck().then(()=>{
        let dn = vm.details_new
        let fn = vm.compiledFormNew
        const indexExt = findIndex(dn,{code:fn.code,id_category:fn.id_category,id_brand:fn.id_brand,id_color:fn.id_color})
        if(indexExt>=0) {
          const indexFactory = findIndex(vm.details_new[indexExt].sizes,{id_size: fn.id_size})
          if(indexFactory<0){
            vm.details_new[indexExt].sizes.push({
              code: fn.barcode,
              factory_barcode: fn.factory_barcode,
              id_size: fn.id_size,
              size_name: fn.size_name,
              qty: fn.qty
            })
          } else {
            vm.details_new[indexExt].sizes[indexFactory].qty += fn.qty
          }
          vm.$nextTick(()=>{
            vm.qtySizeChangedNew(indexExt)
          })
        } else {
          const appendingData = {
            code: fn.code,
            name: fn.name,
            id_category: fn.id_category,
            category_name: fn.category_name,
            id_brand: fn.id_brand,
            brand_name: fn.brand_name,
            id_color: fn.id_color,
            color_name: fn.color_name,
            price: fn.purchase_price,
            sale_price: fn.sale_price,
            qty_total: fn.qty,
            price_total: fn.qty * fn.purchase_price,
            sizes:[
              {
                code: fn.barcode,
                factory_barcode: fn.factory_barcode,
                id_size: fn.id_size,
                size_name: fn.size_name,
                qty: fn.qty
              }
            ]
          }
          vm.details_new.push(appendingData)
        }
      })
      .then(()=>{
        vm.formNew.color = null
        vm.formNew.size = null
        vm.formNew.sale_price = 0
        vm.formNew.purchase_price = 0
        vm.formNew.qty = 1
        vm.formNew.barcode = ''
        vm.formNew.factory_barcode = ''
        this.discPercentChange()
      })
    },
    deleteDetail(i){
      this.details.splice(i, 1)
      this.$nextTick(() => {
        this.discPercentChange()
      })
    },
    deleteDetailNew(i) {
      this.details_new.splice(i, 1)
      this.$nextTick(() => {
        this.discPercentChange()
      })
    }
  },
  mounted(){
    if(localStorage.getItem('labelPrinter')||null) this.qzSetPrinter(localStorage.getItem('labelPrinter'))
  }
}
</script>

<style>

</style>